import { COUNTRIES_REQUIRING_NATIONAL_ID } from '../../constants'
import type { NationalIdCountries } from '../../types'

/**
 * @param country 2-letter country code
 * @return `true` if the selected country requires a national id to be
 * provided, false otherwise.
 */
export function isNationalIdRequiredForCountry(
  country: NationalIdCountries,
): boolean {
  return COUNTRIES_REQUIRING_NATIONAL_ID.includes(country)
}
