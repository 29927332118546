import type { Country } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { FORM_VALID } from '@ds/components/Form'

import translations from '../components/UserInformation/InputAddressNationalId/InputAddressNationalId.translation'
import type { NationalIdCountries, ValidatorWithI18n } from '../types'
import {
  getNationalIdFormat,
  getNationalIdTranslation,
  isNationalIdRequiredForCountry,
} from '../utils'

type AddressBirthdateValues = {
  nationalId: string
  country: Country
  [x: string | number | symbol]: unknown
}

const nationalIDValidation: ValidatorWithI18n<AddressBirthdateValues> =
  (i18n: ReturnType<typeof useI18n>) => (_, values) => {
    const { country, nationalId } = values

    const isNationalIdORequired = isNationalIdRequiredForCountry(
      country as NationalIdCountries,
    )

    if (!isEmpty(country)) {
      if (!isNationalIdORequired && isEmpty(nationalId)) {
        return FORM_VALID
      }

      if (isNationalIdORequired && isEmpty(nationalId)) {
        return i18n(translations.required)
      }

      const nationalIdFormat = getNationalIdFormat(
        country as NationalIdCountries,
      )
      const translation = getNationalIdTranslation(
        country as NationalIdCountries,
      )

      return isEmpty(nationalIdFormat) ||
        nationalIdFormat.regexp.test(nationalId.trim())
        ? FORM_VALID
        : i18n(translation.format)
    }

    return FORM_VALID
  }

export const InputAddressNationalIdValidators = (
  i18n: ReturnType<typeof useI18n>,
) => ({
  isNationalIdValid: nationalIDValidation(i18n),
})
