export default {
  shippingAddressTitle: {
    id: 'funnel_address_confirmation_page_shipping_address_title',
    defaultMessage: 'Confirm your shipping address',
  },
  shippingAddressEditButton: {
    id: 'funnel_address_confirmation_page_shipping_address_edit_button',
    defaultMessage: 'Edit shipping address',
  },
  billingAddressTitle: {
    id: 'funnel_address_confirmation_page_billing_address_title',
    defaultMessage: 'Confirm your billing address',
  },
  billingAddressEditButton: {
    id: 'funnel_address_confirmation_page_billing_address_edit_button',
    defaultMessage: 'Edit billing address',
  },
  collectionPointAddressTitle: {
    id: 'funnel_address_confirmation_page_collection_point_address_title',
    defaultMessage: 'Confirm your shipping details',
  },
  collectionPointAddressEditButton: {
    id: 'funnel_address_confirmation_page_collection_point_address_edit_button',
    defaultMessage: 'Edit shipping details',
  },
  submitButton: {
    id: 'funnel_address_confirmation_page_submit_button',
    defaultMessage: 'Next',
  },
  submitButtonAlternative: {
    id: 'funnel_address_confirmation_page_submit_button_new',
    defaultMessage: 'Continue to payment',
  },
  submitSwapInfoTitle: {
    id: 'funnel_address_confirmation_page_swap_submit_toast_error_title',
    defaultMessage: 'Oups',
  },
  submitSwapInfoError: {
    id: 'funnel_address_confirmation_page_swap_submit_toast_error_message',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  toastClose: {
    id: 'funnel_address_confirmation_page_toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  userInformationTitle: {
    id: 'funnel_address_confirmation_user_information_title',
    defaultMessage: 'Personal information',
  },
  missingData: {
    id: 'funnel_address_confirmation_page_missing_data',
    defaultMessage: 'You need to fill all the required fields.',
  },
}
